<template>
  <div class="app-footer" style="background-image: url(/img/bg-footer-min.jpg)" data-pagearea="footer">
    <div class="page-container">
      <div class="px-2 py-8 desktop:px-40 desktop:py-20">
        <div class="flex flex-wrap pb-14 border-b border-neutral-300">
          <div class="w-full desktop:w-[400px] desktop:pr-14 desktop:border-r border-neutral-300 desktop:mr-14 text-center desktop:text-left">
            <MoleculesGlobalLogo loading="lazy" />
            <div class="py-10 mb-6 border-b border-neutral-300 text-sm">
              <div class="max-w-[450px] mx-auto">
                {{ $t('Naši zákazníci mají k dispozici kamennou prodejnu v Semilech, cca 40 km od Liberce, a v Olomouci. Zboží dodáváme také na Slovensko na Rigad.sk a také do celé Evropy a prakticky celého světa na Rigad.com.') }}
              </div>
            </div>
            <div class="app-footer__social">
              <AtomsTextLink :to="appConfig.externalLinks.facebook" rel="nofollow" target="_blank">
                <AtomsImagesIcon icon="facebook" />
              </AtomsTextLink>
              <AtomsTextLink :to="appConfig.externalLinks.youtube" rel="nofollow" target="_blank">
                <AtomsImagesIcon icon="youtube" />
              </AtomsTextLink>
              <AtomsTextLink :to="appConfig.externalLinks.instagram" rel="nofollow" target="_blank">
                <AtomsImagesIcon icon="instagram" />
              </AtomsTextLink>
            </div>
          </div>
          <div class="app-footer__blocks">
            <div>
              <strong>{{ $t('Informace k nákupu') }}</strong>
              <ul>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(28805)">{{ $t('Stav objednávky') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(26553)">{{ $t('Doprava') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(26551)">{{ $t('Platba') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(4484)">{{ $t('Výměna zboží') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(4481)">{{ $t('Reklamace zboží') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(26558)">{{ $t('Informační centrum') }}</AtomsTextLink>
                </li>
              </ul>
            </div>
            <div>
              <strong>{{ $t('O společnosti') }}</strong>
              <ul>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(26552)">{{ $t('Prodejna Semily') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(20894)">{{ $t('Prodejna Olomouc') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(36225)">{{ $t('Prodejna Ostrava') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(3451)">{{ $t('Obchodní podmínky') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(4480)">{{ $t('O nás') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(3453)">{{ $t('Kontakt') }}</AtomsTextLink>
                </li>
              </ul>
            </div>
            <div>
              <strong>{{ $t('Obchod') }}</strong>
              <ul>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(3449)">{{ $t('Slevy a výhody') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(18438)">{{ $t('Magazín') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getModuleUrl('modules/inspiration')">{{ $t('Inspirace') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(18437)">{{ $t('Slovník pojmů') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(3455)">{{ $t('Ochrana osobních údajů') }}</AtomsTextLink>
                </li>
                <li>
                  <AtomsTextLink :to="useCache().getPageUrl(21913)">{{ $t('Cookies') }}</AtomsTextLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap py-12 border-b border-neutral-300 text-sm text-center desktop:py-14 desktop:text-left">
          <div class="w-full flex items-center justify-center mb-8 desktop:max-w-[240px] desktop:mb-0">{{ $t('Obchod Rigad.cz získal díky spokojenosti ověřených zákazníků prestižní certifikát Zlaté Ověřeno zákazníky.') }}</div>
          <div class="relative flex-1 flex items-center justify-center gap-8 border-neutral-300 before:hidden before:absolute before:left-0 before:top-0 before:bottom-0 before:border-l after:hidden after:absolute after:right-0 after:top-0 after:bottom-0 after:border-r desktop:gap-12 desktop:mx-14 desktop:px-14 desktop:before:block desktop:after:block">
            <AtomsTextLink v-if="locale.language === 'cz' || locale.language === 'sk'" :to="appConfig.externalLinks.zboziCZ" target="_blank" rel="nofollow"><img src="/img/logo-zbozi.png" alt="Zboží.cz - Rigad.cz" title="Rigad.cz" loading="lazy" /></AtomsTextLink>
            <AtomsTextLink v-if="locale.language === 'cz'" :to="appConfig.externalLinks.heurekaCZ" target="_blank" rel="nofollow"><img src="/img/logo-heureka.png" alt="Heureka - Rigad.cz" title="Rigad.cz" loading="lazy" /></AtomsTextLink>
            <AtomsTextLink v-if="locale.language === 'sk'" :to="appConfig.externalLinks.heurekaSK" target="_blank" rel="nofollow"><img src="/img/logo-heureka.png" alt="Heureka - Rigad.sk" title="Rigad.sk" loading="lazy" /></AtomsTextLink>
            <AtomsTextLink :to="cache.getPageUrl(32107)" class="text-center"><img src="/img/badge-nato.png" alt="Logo NSN" title="Rigad.cz" class="mb-2 tablet:max-w-[80px]" loading="lazy" /><span class="text-xs">NCAGE 828DG</span></AtomsTextLink>
          </div>
          <div class="w-full desktop:flex-1 flex items-center gap-6 justify-center mt-12 pt-12 border-t tablet:gap-10 desktop:gap-16 desktop:mt-0 desktop:pt-0 desktop:border-0 border-neutral-300">
            <img src="/img/logo-payment.svg" loading="lazy" :alt="$t('Platba kartou online')" :title="$t('Platba kartou online')" />
            <img src="/img/logo-mastercard.svg" loading="lazy" :alt="$t('Logo MasterCard')" />
            <img src="/img/logo-visa.svg" loading="lazy" :alt="$t('Logo VISA')" />
          </div>
        </div>
        <div class="pt-12 text-xs uppercase text-center desktop:text-left">
          Copyright &copy; 2011-{{ new Date().getFullYear() }} Rigad
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

const appConfig = useAppConfig();
const locale = useLocale().getLocale();
const cache = useCache();

</script>
<style lang="postcss">
.app-footer {

  @apply border-t-4 border-secondary-500 bg-neutral-500 bg-cover bg-center text-neutral-300;

  a {
    @apply hover:no-underline hover:text-primary-400;
  }

  &__social {
    @apply flex justify-center gap-8 text-2xl pb-10 mb-10 border-b border-neutral-300 desktop:justify-between desktop:border-0 desktop:pb-0 desktop:mb-0;

    a {
      @apply p-4;
    }
  }

  &__blocks {
    @apply flex flex-wrap flex-1 gap-8;

    &>div {
      @apply w-full tablet:flex-1 desktop:w-auto leading-8 text-center tablet:text-left;

      &>strong {
        @apply text-neutral-50 block mb-8 text-xl;
      }
    }
  }
}
</style>