<template>

  <ul v-if="items" :class="`primary-nav__items primary-nav__items--${props.level}`" ref="levelContainer">

    <!-- 2nd level in mobile menu "vše v kategorii" -->

    <li v-if="props.parentGroup && props.level === 2" class="desktop:hidden">
      <AtomsTextLink :to="props.parentGroup.page.url" class="block py-4 font-bold text-sm tracking-normal hover:no-underline">
        {{ $t('Vše v kategorii') }} <span class="text-secondary-500">{{ props.parentGroup.name }}
          <AtomsImagesIcon icon="arrow-right" size="sm" />
        </span>
      </AtomsTextLink>
    </li>

    <!-- groups -->

    <li v-for="(item, index) in items" :class="[`primary-nav__item primary-nav__item--${props.level}`, { 'is-open': item.isSubMenuOpen, 'desktop:mb-[136px]': (items.length < 5 && props.level > 1) }]">
      <AtomsTextLink :to="item.page.url" class="primary-nav__link" :data-text="item.name">
        <div class="primary-nav__link__wrapper">
          <div class="flex items-center justify-center w-[60px]" :class="{ 'desktop:hidden': props.level < 2 }">
            <AtomsImagesImage class="max-w-[40px] max-h-[40px]" :src="`${useShopZone().value.staticResourcesRoot}/img/groups/icons/${item.id}.png`" :alt="item.name" />
          </div>
          <span class="flex-1">{{ item.name }}</span>
          <AtomsImagesIcon v-if="item.groups?.length > 0" icon="arrow-right-carousel" size="xs" class="primary-nav__link__dropdown" @click.prevent="toggleMenu(item)" />
        </div>
      </AtomsTextLink>
      <primaryNavLevel :parent-group="item" :items="item.groups" :level="props.level + 1" @closeMenu="closeMenu(item)" />
    </li>

    <!-- vendors -->

    <Transition>
      <template v-if="vendorsShown">
        <li :class="['primary-nav__vendors', `primary-nav__vendors--with-${numberOfExtraItems}-extra-items`]">
          <ul class="gap-[0.75rem] mx-8 mb-4 desktop:grid desktop:mx-[1.5rem]" style="grid-template-columns: repeat(4, 1fr);">
            <li class="hidden"></li>
            <li v-for="item in vendorItems" :class="[`primary-nav__item primary-nav__item--2`]">
              <AtomsTextLink :to="`${props.parentGroup.page.url}#!${i18n.t('znacka')}=${item.filterUrlValue}`" class="primary-nav__link" :data-text="item.name" @click="extraItemClicked">
                <div class="primary-nav__link__wrapper">
                  <div class="flex items-center justify-center w-[60px]" :class="{ 'desktop:hidden': props.level < 2 }">
                    <AtomsImagesImage class="max-w-[40px] max-h-[40px]" :src="`${useShopZone().value.staticResourcesRoot}/img/groups/vendors1/${item.id}.png`" :alt="item.name" />
                  </div>
                  <span class="flex-1">{{ item.name }}</span>
                </div>
              </AtomsTextLink>
            </li>
          </ul>
        </li>
      </template>
    </Transition>

    <!-- footer of desktop dropdown -->

    <li v-if="props.level === 2" class="self-end -mx-6 col-span-4">
      <ul class="primary-nav__extra-items">
        <li v-if="props.parentGroup.containsWareTags.some(tag => tag.type === 'NEW')">
          <AtomsTextLink :to="`${props.parentGroup.page.url}#!tag=news&sort=newest`" @click="extraItemClicked">
            <AtomsImagesIcon icon="megaphone" />
            {{ $t('Novinky') }}
          </AtomsTextLink>
        </li>
        <li v-if="props.parentGroup.containsWareTags.some(tag => tag.type === 'ACTION')">
          <AtomsTextLink :to="`${props.parentGroup.page.url}#!tag=sale`" @click="extraItemClicked">
            <AtomsImagesIcon icon="shopping-bag-percent" />
            {{ $t('Akce a slevy') }}
          </AtomsTextLink>
        </li>
        <li v-if="props.parentGroup.containsWareTags.some(tag => tag.type === 'CLEARANCESALE')">
          <AtomsTextLink :to="`${props.parentGroup.page.url}#!tag=clearance`" @click="extraItemClicked">
            <AtomsImagesIcon icon="sale-sign" />
            {{ $t('Výprodej') }}
          </AtomsTextLink>
        </li>
        <li v-if="true" :class="{ '!border-neutral-500 pt-[1px] -mt-[1px] relative z-2 bg-neutral-500 desktop:pt-0 desktop:mt-0': vendorsShown }">
          <AtomsTextLink @click="toggleVendors" class="cursor-pointer">
            <AtomsImagesIcon icon="signpost" />
            {{ $t('Značky A-Z') }}
          </AtomsTextLink>
        </li>
        <li v-if="true">
          <AtomsTextLink :to="props.parentGroup.page.url">
            <AtomsImagesIcon icon="bag" />
            {{ $t('Všechny produkty') }}
          </AtomsTextLink>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script setup>

const props = defineProps({
  parentGroup: Object,
  items: Array,
  level: Number
});

const utils = useUtils();
const i18n = useI18n();
const locale = useLocale().getLocale();

const items = ref(props.items?.sort((a, b) => b.priority - a.priority));

const levelContainer = ref(null);

const emit = defineEmits(['openMenu', 'closeMenu']);

const toggleMenu = (item) => {
  item.isSubMenuOpen = !item.isSubMenuOpen;
}

const closeMenu = (item) => {
  items.value.find(q => q == item).isSubMenuOpen = false;
}

let vendorItems = ref(null);
const vendorsShown = ref(false);

const getVendors = async () => {

  const vendorLValues = (await useApiFetch(`/api/groupVendors?groupId=${props.parentGroup?.id}&languageId=${locale.languageId}&currencyId=${locale.currencyId}`)).groups?.[0].wares?.itemsProperties.properties?.[0]?.values.map(value => value.lValue);

  if (vendorLValues?.length) {
    const vendors = (await useApiFetch(`/api/vendorImages?languageId=${locale.languageId}&currencyId=${locale.currencyId}&lValues=${vendorLValues.join(',')}`)).custom.ta.vendors.items;

    vendorItems.value = vendors.map(vendor => {
      return {
        id: vendor.group.id,
        name: vendor.name,
        filterUrlValue: utils.slugify(vendor.name)
      }
    });
  };
}

const toggleVendors = async () => {
  if (!vendorItems.value) {
    await getVendors();
  }

  vendorsShown.value = !vendorsShown.value;

  if (vendorsShown.value) {
    console.log(levelContainer.value);
    document.getElementById('stickyDesktopAnchor').scrollTo({ top: levelContainer.value.offsetTop, behavior: 'smooth' });
  }
}

const numberOfExtraItems = computed(() => {
  let result = 2;

  if (props.parentGroup.containsWareTags.some(tag => tag.type === 'NEW')) {
    result++;
  }

  if (props.parentGroup.containsWareTags.some(tag => tag.type === 'ACTION')) {
    result++;
  }

  if (props.parentGroup.containsWareTags.some(tag => tag.type === 'CLEARANCESALE')) {
    result++;
  }

  return result;
});

const extraItemClicked = (item) => {
  useState('scrollToCurrentFilter').value = true;
}

</script>
<style lang="postcss">
.primary-nav__vendors {
  @apply absolute top-0 left-0 right-0 bg-neutral-50 overflow-auto;

  &--with-2-extra-items {
    @apply bottom-[7.5rem] desktop:bottom-[4.5rem];
  }

  &--with-3-extra-items {
    @apply bottom-[11.25rem] desktop:bottom-[4.5rem];
  }

  &--with-4-extra-items {
    @apply bottom-[15rem] desktop:bottom-[4.5rem];
  }

  &--with-5-extra-items {
    @apply bottom-[18.75rem] desktop:bottom-[4.5rem];
  }

  .primary-nav__link__wrapper {
    @screen maxtablet {
      @apply border-b-0;
    }
  }
}
</style>
